<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{Hotel.Nome}}</h5>
  <i type="button" class="btn btn-search" data-bs-dismiss="modal" class="fas fa-times" (click)="fecharModal()"></i>
</div>
<div class="modal-body">
  <div *ngIf="Hotel.Quartos.length > 1 && Hotel.MesmaPensao">
    <h5 class="modal-title"><span class="text-danger">AVISO</span>: Esse fornecedor exige o mesmo regime de alimentação para todos os quartos selecionados para reserva.</h5>
  </div>
  <div class="row" *ngFor="let quarto of Hotel.Quartos; let c = index">
    <div>
      <h4 class="p-4"> {{"Quarto" | translate}} {{Hotel.opcoes[c][0].Pesquisa}} </h4>
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">{{"Descrição" | translate}}</th>
              <th scope="col">{{"Regime" | translate}}</th>
              <th scope="col">{{"Valor" | translate}}</th>
              <th scope="col">{{"Reembolsável" | translate}}</th>
              <th scope="col">{{"Quantidade" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let q of Hotel.opcoes[c]; let i = index">
              <td scope="row">
                <input class="form-check-input" [(ngModel)]="Hotel.Quartos[c]" [value]="q" type="radio"
                  id="quarto{{c}}opcao{{i}}" name="quarto{{c}}opcao{{i}}" (click)="selecionaQuarto(Hotel,q.Agrupador)">
                <label class="form-check-label" for="quarto{{c}}opcao{{i}}" style="margin-left: 12px">
                  {{q.Descricao}}
                </label>
              </td>
              <td scope="col"><label class="form-check-label" for="quarto{{c}}opcao{{i}}">{{q.DescricaoPensao}}</label></td>
              <td> <label class="form-check-label" for="quarto{{c}}opcao{{i}}">{{q.MoedaListagem.Sigla}} {{q.ValorListagem | currency :'BRL':'':'1.2-2'}}</label></td>
              <td style="text-align: center;"> <label class="form-check-label" for="quarto{{c}}opcao{{i}}">{{(q.Reembolsavel) ? 'Sim' : 'Não'}}</label></td>
              <td scope="col"> <label class="form-check-label" for="quarto{{c}}opcao{{i}}">{{q.Quantidade}}</label></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex">
      <strong class="titulos-quartos">{{"Pessoas" | translate}}:</strong>
      <span class="titulos-quartos-baixo">{{quarto.Adultos}} {{"Adulto" | translate}}(s)</span> <span
        *ngIf="quarto.Criancas > 0" class="titulos-quartos-baixo"> / {{quarto.Criancas}} {{"Criança" |
        translate}}(s)</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <h5 class="text-danger" style="font-size: 18px;" *ngIf="!pensoesIguais()">AVISO: Esse fornecedor exige o mesmo regime de alimentação para todos os quartos selecionados para reserva.</h5>
  <button class="btn btn-success mt-3" [disabled]="!pensoesIguais()" (click)="selecionou()"> {{pacote ? "Alterar" : "Adicionar" | translate}} </button>
</div>